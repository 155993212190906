export let ModulesTypes;
(function (ModulesTypes) {
    ModulesTypes["IMAGE_SLIDER"] = "1";
    ModulesTypes["PRODUCT_SLIDER_2"] = "2";
    ModulesTypes["PRODUCT_SLIDER_4"] = "4";
    ModulesTypes["LINK_SLIDER"] = "9";
    ModulesTypes["TIMER_BANNER"] = "11";
    ModulesTypes["THUMBNAIL_IMAGE_SLIDER"] = "12";
    ModulesTypes["SMALL_ICON_SLIDER"] = "13";
    ModulesTypes["IMAGE_BANNER"] = "14";
    ModulesTypes["IMAGE_SEPERATOR"] = "15";
    ModulesTypes["MULTIPLE_IMAGE_BANNER"] = "16";
})(ModulesTypes || (ModulesTypes = {}));

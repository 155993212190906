export let SliderSizeType;
(function (SliderSizeType) {
    SliderSizeType[SliderSizeType["DESKTOP"] = 0] = "DESKTOP";
    SliderSizeType[SliderSizeType["TABLET"] = 1] = "TABLET";
    SliderSizeType[SliderSizeType["MOBILE"] = 2] = "MOBILE";
})(SliderSizeType || (SliderSizeType = {}));
const sizes = [];
sizes[SliderSizeType.DESKTOP] = {
    width: 1366,
    height: 240
};
sizes[SliderSizeType.TABLET] = {
    width: 768,
    height: 342
};
sizes[SliderSizeType.MOBILE] = {
    width: 360,
    height: 212
};
export const SliderSizes = sizes;
